

    export default {
      props: {
      navsMenu: Array
  },
    computed: {
      header() {
      return this.$t('header')
    },
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  },
    created() {
      window.addEventListener('scroll', this.handleSCroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleSCroll)
    },
    methods: {
      handleSCroll(event) {
      const header = document.querySelector('.topbar')
    if (
        window.scrollY > 100 &&
    !header.className.includes('topbar--fixed-top')
    ) {
      header.classList.add('topbar--fixed-top')
    } else if (window.scrollY < 100) {
      header.classList.remove('topbar--fixed-top')
    }
    }
  }
}

