
import { Fancybox } from '@fancyapps/ui'

export default {
  data () {
    return {
      menuloder: [],
      impdate: null,
      text: null,
      hasNav: false
    }
  },
  head: {
    __dangerouslyDisableSanitizers: ['script'],
    script: [
      {
        hid: 'pcgf',
        innerHTML: `{
            "@context": "https://schema.org/",
                "@type": "Organization",
                "name": "บริษัท เพอร์เฟค คอมพาเนียน กรุ๊ป จำกัด",
                "address": {
                    "streetAddress": "1 เอ็มไพร์ทาวเวอร์ สาทรใต้ แขวงยานนาวา เขตสาทร กรุงเทพฯ 10120",
                    "addressLocality": "Bangkok",
                    "addressRegion": "Thailand",
                    "postalCode": "10120",
                    "addressCountry": "TH"
                    
                },
                "url": "https://www.perfectcompanion.com/th",
                "logo": "https://www.perfectcompanion.com/assets/home/logo.png",
                "description": "บริษัท เพอร์เฟค คอมพาเนียน กรุ๊ป จํากัด จัดตั้งขึ้นเพื่อยกระดับคุณภาพชีวิตของคนและสัตว์เลี้ยง ผ่านการดูแลเอาใจใส่และคิดค้นผลิตภัณฑ์อย่างต่อเนื่อง เรามั่นใจว่าสินค้าและบริการของเราจะมีส่วนช่วยในการสร้างความสัมพันธ์ที่ดีระหว่างคุณและสัตว์เลี้ยงของคุณอย่างแท้จริง",
                "phone": "+66-2091-9600"
                }`,
        body: true,
        type: 'application/ld+json',
        charset: 'utf-8'
      }
    ]
  },
  created () {
    this.getCategory(),
    this.pageInt()
  },
  methods: {
    async getCategory () {
      const getCategory = await this.$axios.$get(process.env.BASE_URL + '/backoffice/category/cats?api=true&zmode=nav')
      this.menuloder = getCategory.cate
      this.hasNav = true
      if (getCategory.imp !== null) {
        if (typeof this.$cookies.get('importantDay') === 'undefined') {
          const fancybox = Fancybox.show([
            {
              src: process.env.BASE_URL + '/uploads/importantDay/' + getCategory.imp.imp_images,
              alt: getCategory.imp.imp_name,
              type: 'image'
            }
          ], {
            on: {
              closing: (fancybox) => {
                this.$cookies.set('importantDay', true, {
                  path: '/',
                  maxAge: 60 * 60 * 24 * 1
                })
              }
            }
          })
        }
      }
    },
    pageInt () {
      console.log('Develop by', 'PNA Digital Co.,Ltd')
      window.dataLayer = window.dataLayer || []
      function gtag () { dataLayer.push(arguments) }
      gtag('js', new Date())
      gtag('config', 'UA-23707524-1')
    }
  }
}
