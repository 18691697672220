
export default {
    props: ['error'],
  data () {
    return {
      BASE_URL: process.env.BASE_URL,
      title: 'หน้าที่ต้องการ'
    }
  },

  head () {
    return {
      title: this.title,
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'Perfect companion group co. ltd. thailand บริษัท Perfect Companion Group (เพอร์เฟค คอมพาเนียน กรุ๊ป) ผู้นำด้านการผลิตและจำหน่ายอุปกรณ์สัตว์เลี้ยง อาหารสัตว์ ออนไลน์ คุณภาพดี ครบครันเรื่องสุขภาพสัตว์เลี้ยง ร้านออนไลน์ที่คนรักสัตว์ไว้วางใจ'
        }
      ]
    }
  },
  computed: {
    product () {
      return this.$t('product')
    },
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  }
}
