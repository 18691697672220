
export default {
  props: {
    navsMenu: Array
  },
  computed: {
    header () {
      return this.$t('header')
    },
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  }
}
